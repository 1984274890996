<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :pdf-quality="2"
      :manual-pagination="false"
      :pdf-format="typeFormat"
      :pdf-orientation="orientation"
      pdf-content-width="100%"
      ref="html2Pdf"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      :html-to-pdf-options="htmlToPdfOptions"
    >
      <section slot="pdf-content" v-html="format"></section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  props: {
    item: Object,
    format: String,
    orientation: {
      type: String,
      default: "portrait",
    },
    typeFormat: {
      type: String,
      default: "a4",
    },
  },

  data() {
    return {};
  },

  components: {
    VueHtml2pdf,
  },

  computed: {
    htmlToPdfOptions() {
      return {
        filename: `${this.item.number}`,
        margin: [24.892, 20.066],
      };
    },
  },

  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    onProgress() {},
    hasStartedGeneration() {},
    hasGenerated() {},
  },
};
</script>