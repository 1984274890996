var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"create-transection","centered":"","scrollable":"","size":"xl","no-close-on-backdrop":""},on:{"hidden":_vm.onHidden},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"dark"},on:{"click":cancel}},[_vm._v(" "+_vm._s(_vm.isReadOnly ? "ปิด" : "ยกเลิก")+" ")]),(!_vm.isReadOnly)?_c('b-button',{attrs:{"size":"sm","variant":"primary"}},[(_vm.isSubmiting)?_c('b-spinner',{attrs:{"small":"","label":"submiting..."}}):_c('span',{on:{"click":_vm.createTransection}},[_vm._v("ยืนยัน")])],1):_vm._e()]}}]),model:{value:(_vm.$show),callback:function ($$v) {_vm.$show=$$v},expression:"$show"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(!_vm.isReadOnly)?_c('filter-master',{attrs:{"m-headquarter-id":!_vm.authUser.canAccessAllHeadQuarters ? _vm.authUser.mHeadquarterId : null,"m-division-id":!_vm.authUser.canAccessAllDivisions ? _vm.authUser.mDivisionId : null,"m-station-id":!_vm.authUser.canAccessAllStations ? _vm.authUser.mStationId : null,"disabledInput":{
          headQuarter:
            !_vm.authUser.canAccessAllHeadQuarters &&
            _vm.authUser.mHeadquarterId !== null,
          division:
            !_vm.authUser.canAccessAllDivisions && _vm.authUser.mDivisionId !== null,
          station:
            !_vm.authUser.canAccessAllStations && _vm.authUser.mStationId !== null,
        },"hiddenInput":{}},model:{value:(_vm.formfilter),callback:function ($$v) {_vm.formfilter=$$v},expression:"formfilter"}}):_vm._e()],1)],1),_c('transection-teaching-dare-table',{staticClass:"mt-4",attrs:{"fields":_vm.fields,"params":_vm.transectionTableParams,"isReadOnly":_vm.isReadOnly},on:{"selectTeachingDares":_vm.selectTeachingDares}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }