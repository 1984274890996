<template>
  <div>
    <page-content>
      <page-title heading="รายงานขอเบิกค่าตอบแทนการสอน">
        <template v-slot:body>
          <div class="mt-3 px-3">
            <h4 class="text-danger font-weight-bold">ข้อมูลครูตำรวจ</h4>
            <div>
              <h6 class="font-weight-bold">ชื่อ-นามสกุล : {{ name }}</h6>
              <h6 class="font-weight-bold">ยศ : {{ rank }}</h6>
              <!-- <h6 class="font-weight-bold">รหัสครูตำรวจ :</h6> -->
            </div>
          </div>
        </template>

        <template v-slot:right>
          <b-button variant="danger" @click="onCreate">เพิ่มรายงาน</b-button>
        </template>
      </page-title>

      <transection-table
        ref="table_transection"
        class="mt-4"
        :fields="fields"
        @seeDetail="onSeeDetail"
      >
        <!-- <template v-slot:header-actions>
          <b-button variant="primary" size="sm" @click="onCreate"
            >เพิ่ม</b-button
          >
        </template> -->
      </transection-table>

      <create-transection
        v-model="showModalTransection"
        :transactionId="transactionId"
        :isReadOnly="isReadOnly"
        @create:success="onCreateSuccess"
        @hide="clearData"
      ></create-transection>

      <!-- <generate-pdf
        id="gen-pdf-create-transaction"
        key="gen-pdf-create-transaction"
        ref="gen_pdf"
        :item="item"
        :format="format"
      ></generate-pdf> -->
    </page-content>
  </div>
</template>

<script>
import CreateTransection from "../../components/modal/CreateTransection";
import TransectionTable from "../../components/table/Transection";
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import { Auth, MRank } from "../../models";
// import { generateTemplate } from "../../helpers/template-pdf-transaction";
// import GeneratePdf from "../../components/report/GeneratePdf";
export default {
  data() {
    return {
      name: "",
      rank: "",
      showModalTransection: false,
      transactionId: null,
      isReadOnly: false,
      // format: "",
      // item: {},
    };
  },

  components: {
    PageContent,
    PageTitle,
    TransectionTable,
    CreateTransection,
    // GeneratePdf,
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    fields() {
      const fields = [
        // { key: "order", label: "ลำดับ" },
        // { key: "station", label: "สถานี" },
        // { key: "school", label: "โรงเรียน" },
        // { key: "amphur", label: "อำเภอ" },
        // { key: "province", label: "จังหวัด" },
        // { key: "room", label: "ห้องเรียน", class: "text-center" },
        // { key: "fiscal_year", label: "ปีการศึกษา", class: "text-center" },
        // { key: "actual_student", label: "จำนวนนักเรียน", class: "text-center" },
        { key: "number", label: "หมายเลข", class: "text-center" },
        { key: "total_time", label: "ชั่วโมงการสอน", class: "text-center" },
        { key: "total_money", label: "จำนวนเงิน", class: "text-center" },
        {
          key: "created_at",
          label: "วันที่สร้าง",
          class: "text-center",
        },
      ];

      return fields;
    },
  },

  methods: {
    onCreate() {
      this.showModalTransection = true;
    },

    async init() {
      try {
        const authUser = Auth.user();

        this.name = `${authUser.first_name} ${authUser.last_name}`;
        await MRank.api().findAll();

        this.rank = MRank.find(authUser.mRankId)
          ? MRank.find(authUser.mRankId).m_rank_name
          : "";
      } catch (error) {
        console.error(error);
      }
    },

    onCreateSuccess() {
      this.$refs.table_transection.refresh();
      // this.item = { ...v };
      // this.format = generateTemplate(this.authUser, this.item);
      // if (this.$refs.gen_pdf) {
      //   this.$nextTick(() => {
      //     const { $refs } = this.$refs.gen_pdf;
      //     $refs.html2Pdf.generatePdf();
      //   });
      // }
    },

    onSeeDetail(item) {
      this.transactionId = item.id;
      this.isReadOnly = true;
      this.showModalTransection = true;
    },
    clearData() {
      this.transactionId = null;
      this.isReadOnly = false;
    },
  },

  mounted() {
    this.init();
  },
};
</script>
