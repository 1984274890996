<template>
  <b-modal
    id="create-transection"
    v-model="$show"
    centered
    scrollable
    size="xl"
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <b-row>
      <b-col cols="12">
        <filter-master
          v-if="!isReadOnly"
          v-model="formfilter"
          :m-headquarter-id="
            !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
          "
          :m-division-id="
            !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
          "
          :m-station-id="
            !authUser.canAccessAllStations ? authUser.mStationId : null
          "
          :disabledInput="{
            headQuarter:
              !authUser.canAccessAllHeadQuarters &&
              authUser.mHeadquarterId !== null,
            division:
              !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
            station:
              !authUser.canAccessAllStations && authUser.mStationId !== null,
          }"
          :hiddenInput="{}"
        >
        </filter-master>
      </b-col>

      <!-- <b-col cols="10" md="6" class="mt-2 pr-0">
        <b-form-group class="px-2">
          <b-input-group class="input-group-seamless">
            <b-form-input
              placeholder="ค้นหารายชื่อโรงเรียน"
              v-model="keyword"
              @keyup.enter="onKeywordSearched"
            ></b-form-input>

            <b-input-group-text slot="prepend">
              <font-awesome-icon class="mx-auto" icon="search" />
            </b-input-group-text>

            <b-input-group-text v-show="keyword.length" slot="append">
              <a href="javascript:void(0)" @click="clearKeyword">
                <font-awesome-icon class="mx-auto" icon="window-close" />
              </a>
            </b-input-group-text>
          </b-input-group>
        </b-form-group>
      </b-col> -->

      <!-- <b-col cols="2" class="mt-2 pl-0">
        <b-button variant="primary" block size="sm" @click="onKeywordSearched"
          >ค้นหา</b-button
        >
      </b-col> -->
    </b-row>
    <transection-teaching-dare-table
      class="mt-4"
      :fields="fields"
      :params="transectionTableParams"
      :isReadOnly="isReadOnly"
      @selectTeachingDares="selectTeachingDares"
    >
    </transection-teaching-dare-table>

    <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="dark" @click="cancel">
        {{ isReadOnly ? `ปิด` : `ยกเลิก` }}
      </b-button>

      <b-button v-if="!isReadOnly" size="sm" variant="primary">
        <b-spinner v-if="isSubmiting" small label="submiting..."></b-spinner>
        <span v-else @click="createTransection">ยืนยัน</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import TransectionTeachingDareTable from "../../components/table/TransectionTeachingDare";
import { Transection, Auth } from "../../models";
import FilterMaster from "../../components/form/FilterMaster";
export default {
  components: {
    TransectionTeachingDareTable,
    FilterMaster,
  },

  props: {
    show: Boolean,
    transactionId: Number,
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      teachingDares: [],
      isSubmiting: false,
      formfilter: {},
      keyword: "",
    };
  },

  model: {
    prop: "show",
    event: "update",
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    transectionTableParams() {
      const params = {};
      const { formfilter } = this;

      if (this.isReadOnly) {
        if (this.transactionId) {
          this.$set(formfilter, "transactionId", this.transactionId);
        } else {
          this.$set(formfilter, "transactionId", 0);
        }
      } else {
        // if (!this.authUser.isAdminType) {
        //   this.$set(params, "userProfileId", this.authUser.id);
        // }
      }

      this.$set(params, "userProfileId", this.authUser.id);

      return {
        ...formfilter,
        ...params,
      };
    },

    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },

    fields() {
      const additionalFields = [];

      if (!this.isReadOnly) {
        additionalFields.push({ key: "select", label: "เลือก" });
      }

      // if (this.authUser.isAdminType) {
      //   additionalFields.push({
      //     key: "user_name",
      //     label: "ชื่อ-นามสกุล",
      //     sortable: true,
      //   });
      // }

      const fields = [
        { key: "station", label: "สถานี", sortable: true },
        { key: "school", label: "โรงเรียน", sortable: true },
        // { key: "amphur", label: "อำเภอ" },
        // { key: "province", label: "จังหวัด" },
        {
          key: "classRoom",
          label: "ห้องเรียน",
          class: "text-center",
          sortable: true,
        },
        {
          key: "yearTerm",
          label: "ปีการศึกษา",
          class: "text-center",
          sortable: true,
        },
        {
          key: "actual_student",
          label: "จำนวนนักเรียน",
          class: "text-center",
          sortable: true,
        },
        { key: "time", label: "ชั่วโมงการสอน", class: "text-center" },
        { key: "totalPrice", label: "จำนวนเงิน", class: "text-center" },
      ];

      return [...additionalFields, ...fields];
    },
  },

  methods: {
    onHidden() {
      this.formfilter = {};

      this.$emit("hide");
    },

    onKeywordSearched() {
      this.$set(this.formfilter, "keyword", this.keyword);
    },

    clearKeyword() {
      this.keyword = "";
      this.$delete(this.formfilter, "keyword");
    },
    selectTeachingDares(v) {
      this.teachingDares = v;
    },

    async createTransection() {
      if (this.teachingDares && this.teachingDares.length == 0) {
        return this.$toast.error("กรุณาเลือกชั้นเรียนที่ต้องการเบิกค่าตอบแทน");
      }
      this.isSubmiting = true;
      let total_time = 0;
      let total_money = 0;
      let teachingDareIds = [];
      let date = new Date();
      for (let index = 0; index < this.teachingDares.length; index++) {
        const element = this.teachingDares[index];
        total_time += element.time;
        total_money += element.totalPrice;
        teachingDareIds.push({ id: element.id });
      }
      try {
        const { response } = await Transection.api().create({
          total_time,
          total_money,
          date,
          teachingDareIds,
        });

        const { data } = response;

        if (data && data.response_status == "SUCCESS") {
          this.$toast.success("ทำเรื่องเบิกค่าตอบแทนสำเร็จ");
          this.$bvModal.hide("create-transection");
          this.$emit("create:success", data.data);
        } else {
          this.$toast.error(
            "ไม่สามารถทำเรื่องเบิกค่าตอบแทนได้ กรุณาลองใหม่อีกครั้ง"
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isSubmiting = false;
      }
    },
  },
};
</script>
