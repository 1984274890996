const numToWord = {
    '1': 'หนึ่ง',
    '2': 'สอง',
    '3': 'สาม',
    '4': 'สี่',
    '5': 'ห้า',
    '6': 'หก',
    '7': 'เจ็ด',
    '8': 'แปด',
    '9': 'เก้า',
    '0': 'ศูนย์',
}

function formatThai(date = "") {
    const tmpDate = new Date(date)
    return tmpDate.toLocaleDateString('th-TH', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })
}

function formatCurrencyThai(number = 0) {
    return new Intl.NumberFormat('th-TH').format(number);
}

function formatCurrencyNumberToText(number = 0) {
    let str = number.toString().split('')
    let wording = "";
    if (str && str.length == 6) {
        wording = `${numToWord[str[0]]}แสน${str[1] == '0' ? '' : numToWord[str[1]] + 'หมื่น'}${str[2] == '0' ? '' : numToWord[str[2]] + 'พัน'}${str[3] == '0' ? '' : numToWord[str[3]] + 'ร้อย'}บาทถ้วน`
    } else if (str && str.length == 5) {
        wording = `${numToWord[str[0]]}หมื่น${str[1] == '0' ? '' : numToWord[str[1]] + 'พัน'}${str[2] == '0' ? '' : numToWord[str[2]] + 'ร้อย'}บาทถ้วน`
    } else if (str && str.length == 4) {
        wording = `${numToWord[str[0]]}พัน${str[1] == '0' ? '' : numToWord[str[1]] + 'ร้อย'}บาทถ้วน`
    } else if (str && str.length == 3) {
        wording = `${numToWord[str[0]]}ร้อยบาทถ้วน`
    }

    return wording
}

export function generateTemplate(user = {}, item = {}, selected = "") {
    let schools = "";
    let lesson_plan_counts = 0;
    let teaching_dare_table = "";
    let total_time_lesson_plan = 0;
    let total_price_lesson_plan = 0;
    let pricePerHrs = 0
    if (selected == "0") {
        pricePerHrs = 300;
    }
    if (selected == "1") {
        pricePerHrs = 600;
    }
    if (item && item.teaching_dares) {
        for (let index = 0; index < item.teaching_dares.length; index++) {
            const {
                m_school = {},
                m_class = {},
                actual_student,
                teaching_plans,
                term,
                fiscal_year
            } = item.teaching_dares[index];

            schools += `<p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-indent:.5in;'><span style='font-family:"TH Niramit AS";'>โรงเรียน ${m_school?.m_school_name ? m_school.m_school_name : ""
                } ชั้น ${m_class?.m_class_name ? m_class.m_class_name : "..."
                } </span></p>
                  <p class="mb-3" style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-indent:.5in;'><span style='font-family:"TH Niramit AS";'>จำนวนนักเรียน ${actual_student ? actual_student : 0
                } คน</span></p>`;

            lesson_plan_counts += teaching_plans.length;

            let hours = 0;
            let mins = 0;

            if (teaching_plans) {
                for (let index = 0; index < teaching_plans.length; index++) {
                    const element = teaching_plans[index].totalTime;
                    if (element) {
                        const times = element.split(":");
                        hours += parseInt(times[0]);
                        mins += parseInt(times[1]);
                    }
                }
            }

            total_time_lesson_plan = hours + mins / 60;
            total_price_lesson_plan += total_time_lesson_plan * pricePerHrs

            teaching_dare_table += `
                <tr>
                    <td style="width: 83%; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0in 5.4pt; vertical-align: bottom;">
                        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp;ค่าตอบแทนวิทยากรตามโครงการการศึกษาเพื่อต่อต้าน</span></p>
                        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>การใช้ยาเสพติดในเด็กนักเรียน (โครงการ&nbsp;</span><span style='font-family:"TH Niramit AS";'>D.A.R.E. ประเทศไทย) ประจำภาคเรียนที่ ${term ? term : ''}/${fiscal_year ? fiscal_year : ''}</span></p>
                        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>ณ โรงเรียน ${m_school?.m_school_name ? m_school.m_school_name : ""}</span></p>
                        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>ตำบล .............................&nbspอำเภอ ${m_school?.m_amphur?.m_amphur_name ? m_school.m_amphur.m_amphur_name : ""}</span></p>
                        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>จังหวัด ${m_school?.m_province?.m_province_name ? m_school.m_province.m_province_name : ""}</span></p>
                        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>ดำเนินการระหว่างวันที่ ${teaching_plans[0] ? formatThai(teaching_plans[0].date) : ""}</span></p>
                        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>ถึงวันที่ ${teaching_plans[teaching_plans.length - 1] ? formatThai(teaching_plans[teaching_plans.length - 1].date) : ""}</span></p>
                        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>จำนวน ${parseInt(total_time_lesson_plan)} ชั่วโมง ๆ ละ ${pricePerHrs} บาท ต่อห้อง รวม 1 ห้องเรียน&nbsp;</span></p>
                        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>เป็นเงิน</span></p>
                    </td>
                    
                    <td style="width: 17%; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: bottom;">
                        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
                        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
                        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
                        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
                        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
                        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>${formatCurrencyThai(parseInt(total_time_lesson_plan) * pricePerHrs)}</span><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
                    </td>
                    </tr>
            `
        }
    }

    return `
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><strong><span style='font-size:24px;font-family:"TH Niramit AS";'>เอกสารประกอบการรายงานเพื่อขอเบิกค่าตอบแทนการสอน</span></strong></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><strong><span style='font-family:"TH Niramit AS";'>โครงการการศึกษาเพื่อต่อต้านการใช้ยาเสพติดในเด็กนักเรียน (&nbsp;</span></strong><strong><span style='font-family:"TH Niramit AS";'>D.A.R.E.ประเทศไทย )</span></strong></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><strong><span style='font-family:"TH Niramit AS";'>( ภาคเรียนที่ .........................)</span></strong></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><strong><span style='font-family:"TH Niramit AS";'>&nbsp;</span></strong></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><strong><span style='font-family:"TH Niramit AS";'>ชื่อครูตำรวจ&nbsp;</span></strong><strong><span style='font-family:"TH Niramit AS";'>D.A.R.E.&nbsp;</span></strong></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ชื่อ ${user?.first_name ? user.first_name : ""} ${user?.last_name ? user.last_name : ""} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ชั้นยศ ${user?.m_rank?.m_rank_name ? user.m_rank.m_rank_name : "..."} </span></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; รหัสครูตำรวจ D.A.R.E&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;..</span></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><strong><span style='font-family:"TH Niramit AS";'>ขอเบิกค่าตอบแทนการสอนจำนวน ${item?.teaching_dares ? item?.teaching_dares.length : 0} ห้องเรียน ดังนี้</span></strong></p>
        ${schools}
        <br>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><strong><span style='font-family:"TH Niramit AS";'>เอกสารประกอบการเบิกค่าตอบแทนการสอน</span></strong></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>1. ใบสำคัญรับเงิน&nbsp;</span></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>2. แผนการสอน ${lesson_plan_counts} &nbsp;บทเรียน (${item?.teaching_dares ? item?.teaching_dares.length : 0} ห้องเรียน)&nbsp;</span></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>3. บันทึกการสอน ${lesson_plan_counts} บทเรียน &nbsp;พร้อมภาพกิจกรรมแต่ละบทเรียน (${item?.teaching_dares ? item?.teaching_dares.length : 0} ห้องเรียน)&nbsp;</span></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>4. รายชื่อนักเรียนที่สอน (พร้อมเลข 13 หลัก และรหัสนักเรียน&nbsp;D.A.R.E.)</span></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>5. สำเนาบัตรประจำตัวประชาชน</span></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>6. สำเนาหน้าสมุดบัญชีครูตำรวจ&nbsp;D.A.R.E. (ธนาคารกรุงไทย) (${item?.teaching_dares ? item?.teaching_dares.length : 0} ห้องเรียน)</span></p>
        <p><span style='page-break-after: always;font-size:21px;font-family:"TH Niramit AS";'>7. แบบรายงานการบันทึกการสอนในระบบฐานข้อมูล&nbsp;D.A.R.E.</span></p>
        
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><strong><span style='font-size:24px;font-family:"TH Niramit AS";'>ใบสำคัญรับเงิน</span></strong></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>วันที่...............เดือน.............พ.ศ..............</span></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><br></p>
        <p id="isPasted" style='margin:0in;font-size:21px;font-family:"Sarabun",serif;margin-left:.5in;text-indent:.5in;'><span style='font-family:"TH Niramit AS";'>ข้าพเจ้า...............................................ชั้นยศ..........................................</span></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-size:21px;font-family:"TH Niramit AS";'>อยู่บ้านเลขที่..............หมู่ที่..................ถนน..........................ตำบล..............................</span></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-size:21px;font-family:"TH Niramit AS";'>อำเภอ....................................................จังหวัด...........................................................</span></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-size:21px;font-family:"TH Niramit AS";'>ได้รับเงินจาก............................ตำรวจภูธรจังหวัด..............................................</span></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-size:21px;font-family:"TH Niramit AS";'>ดังรายการต่อไปนี้</span></p>
        
        <p><br></p>
        <table style="border-collapse:collapse;border:none;">
            <tbody>
                <tr>
                    <td style="width: 83%; border: 1pt solid windowtext; padding: 0in 5.4pt; vertical-align: top;">
                        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><strong><span style='font-family:"TH Niramit AS";'>รายการ</span></strong></p>
                    </td>
                    <td style="width: 17%; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0in 5.4pt; vertical-align: top;">
                        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><strong><span style='font-family:"TH Niramit AS";'>จำนวนเงิน</span></strong></p>
                    </td>
                </tr>
            
                    ${teaching_dare_table}
             
                <tr>
                    <td style="border: none; padding: 0in; width: 83%;">
                        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'>&nbsp;</p>
                    </td>
                    <td style="width: 17%; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0in 5.4pt; height: 20.05pt; vertical-align: top;">
                        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>${formatCurrencyThai(total_price_lesson_plan)}</span></p>
                    </td>
                </tr>
            </tbody>
        </table>

        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  จำนวนเงิน &nbsp; (${formatCurrencyNumberToText(total_price_lesson_plan)})</span></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span></p>
        <p style='margin: 0in; font-size: 21px; font-family: "Sarabun", serif; text-align: right;'><span style='font-family:"TH Niramit AS";'>&nbsp; ลงชื่อ..................</span><span style='font-family:"TH Niramit AS";'>&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;.ผู้รับเงิน</span></p>
        <p id="isPasted" style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;( .................................................)</span></p>
        <p id="isPasted" style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp;ชั้นยศ .........................................</span></p>
        <p style='margin: 0in; font-size: 21px; font-family: "Sarabun", serif; text-align: right;'><span style='font-family:"TH Niramit AS";'>&nbsp;</span><span style='font-family:"TH Niramit AS";'>ลงชื่อ<span id="isPasted" style='color: rgb(0, 0, 0); font-size: 21px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: right; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; font-family: "TH Niramit AS";'>..................</span><span style='color: rgb(0, 0, 0); font-size: 21px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: right; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; font-family: "TH Niramit AS";'>&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;...</span></span><span style='font-family:"TH Niramit AS";'>ผู้จ่ายเงิน</span></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;( .................................................)</span></p>
        <p style='margin:0in;font-size:21px;font-family:"Sarabun",serif;text-align:center;'><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span><span style='font-family:"TH Niramit AS";'>&nbsp; &nbsp;ชั้นยศ .........................................</span></p>
    `;
}

export function generateCertificate() {
    return `<head>
    <title>Page 1</title>
    
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <style type="text/css">
    <!--
        p {margin: 0; padding: 0;}	.ft00{font-size:65px;font-family:ABCDEE+2,Bold;color:#000000;}
        .ft01{font-size:36px;font-family:ABCDEE+2,Bold;color:#001f5f;}
        .ft02{font-size:28px;font-family:ABCDEE+2,Bold;color:#001f5f;}
        .ft03{font-size:18px;font-family:ABCDEE+2,Bold;color:#001f5f;}
    -->
    </style>
    </head>
    <body bgcolor="#A0A0A0" vlink="blue" link="blue">
        <div id="page1-div" style="position:relative;width:1440px;height:810px;">
        <img width="50%" height="auto" src="https://dare-user-upload.s3.ap-southeast-1.amazonaws.com/background-certificate/dare-1.jpeg" alt="background image">
        <p style="position:absolute;top:32px;left:240px;white-space:nowrap" class="ft00"><b>สำนักงานตำรวจแห่งชาติ</b></p>
        <p style="position:absolute;top:105px;left:646px;white-space:nowrap" class="ft01"><b>และ</b></p>
        <p style="position:absolute;top:160px;left:448px;white-space:nowrap" class="ft01"><b>โรงเรียนบ้าน.........................</b></p>
        <p style="position:absolute;top:218px;left:450px;white-space:nowrap" class="ft02"><b>ขอมอบประกาศนียบัตรนี้ให้แก่</b></p>
        <p style="position:absolute;top:261px;left:431px;white-space:nowrap" class="ft02"><b>...............................................................</b></p>
        <p style="position:absolute;top:306px;left:477px;white-space:nowrap" class="ft02"><b>เพื่อแสดงว่าได้จบหลักสูตร</b></p>
        <p style="position:absolute;top:349px;left:212px;white-space:nowrap" class="ft02"><b>โครงการการศึกษาเพื่อต่อต้านการใช้ยาเสพติดในเด็กนักเรียน</b></p>
        <p style="position:absolute;top:392px;left:517px;white-space:nowrap" class="ft02"><b>(D.A.R.E.ประเทศไทย)</b></p>
        <p style="position:absolute;top:434px;left:502px;white-space:nowrap" class="ft02"><b>โดยให้คำปฏิญาณตนว่า</b></p>
        <p style="position:absolute;top:478px;left:146px;white-space:nowrap" class="ft02"><b>จะหลีกเลี่ยงแรงกดดันและแรงจูงใจต่าง ๆ ที่จะนำไปสู่การใช้ยาเสพติด</b></p>
        <p style="position:absolute;top:522px;left:316px;white-space:nowrap" class="ft02"><b>ให้ไว้&nbsp;&nbspณ&nbsp;&nbsp;......</b></p>
        <p style="position:absolute;top:626px;left:156px;white-space:nowrap" class="ft02"><b>.............................................</b></p>
        <p style="position:absolute;top:626px;left:744px;white-space:nowrap" class="ft02"><b>พันตำรวจเอก..........................</b></p>
        <p style="position:absolute;top:683px;left:217px;white-space:nowrap" class="ft02"><b>(..........................)(..........................)</b></p>
        <p style="position:absolute;top:745px;left:109px;white-space:nowrap" class="ft03"><b>ผู้อำนวยการโรงเรียน.....................</b></p>
        <p style="position:absolute;top:745px;left:704px;white-space:nowrap" class="ft03"><b>ผู้กำกับการสถานีตำรวจภูธร</b></p>
        <p style="position:absolute;top:739px;left:1073px;white-space:nowrap" class="ft02"><b>.................</b></p>
        </div>
    </body>
    `
}