<template>
  <div class="card__flex card card-box mb-3">
    <div class="card-header pr-2">
      <div class="card-header--title">
        <h4 class="text-danger font-weight-bold">{{ title }}</h4>
      </div>
      <div class="card-header--actions">
        <slot name="header-actions"></slot>
      </div>
    </div>

    <div class="card-body p-0">
      <base-table
        ref="table"
        :provider="fetch"
        :fields="fields"
        :is-busy.sync="isFetching"
        :current-page.sync="currentPage"
        :number-of-pages="numberOfPages"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :per-page="10"
      >
        <template #cell(select)="{ item }">
          <div class="text-center">
            <input
              type="checkbox"
              :id="`checkbox-${item.id}`"
              :name="`checkbox-${item.id}`"
              :value="{ id: `${item.id}`, time: item.time, price: item.price }"
              v-model="selectTeachingDares"
              v-on:change="handleClick"
            />
          </div>
        </template>

        <!-- <template #cell(refresh)="{ item }">
          <div class="text-center">
            <b-button variant="danger" size="sm" @click="deleteTeaching(item)"
              >ลบ</b-button
            >
          </div>
        </template> -->
      </base-table>
    </div>
  </div>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import BaseTable from "./Base";
import { Auth, MRank, TeachingDare } from "../../models";
export default {
  mixins: [formatDateMixin],
  props: {
    title: String,
    fields: Array,
    params: Object,
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    BaseTable,
  },

  data() {
    return {
      isFetching: false,
      currentPage: 1,
      numberOfPages: 1,
      selectTeachingDares: [],
      sortBy: "",
      sortDesc: false,
    };
  },

  watch: {
    params: {
      deep: true,
      handler: "onParamsChanged",
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  methods: {
    onParamsChanged() {
      this.currentPage = 1;
      this.numberOfPages = 1;

      this.refresh();
    },

    handleClick() {
      this.$emit("selectTeachingDares", this.selectTeachingDares);
    },
    async fetch(ctx) {
      let data = [],
        additionalParams = {},
        numberOfPages = 1;

      if (this.isReadOnly) {
        this.$set(additionalParams, "transactionId", this.params.transactionId);
      } else {
        this.$set(additionalParams, "isPayment", false);
      }

      if (this.params && this.params.userProfileId) {
        this.$set(additionalParams, "userProfileId", this.params.userProfileId);
      }

      if (this.params && this.params.mHeadquarterId) {
        this.$set(
          additionalParams,
          "mHeadquarterId",
          this.params.mHeadquarterId
        );
      }

      if (this.params && this.params.mSchoolId) {
        this.$set(additionalParams, "mSchoolId", this.params.mSchoolId);
      }

      if (this.params && this.params.mClassId) {
        this.$set(additionalParams, "mClassId", this.params.mClassId);
      }

      if (this.params && this.params.mDivisionId) {
        this.$set(additionalParams, "mDivisionId", this.params.mDivisionId);
      }

      if (this.params && this.params.mStationId) {
        this.$set(additionalParams, "mStationId", this.params.mStationId);
      }

      if (this.params && this.params.keyword) {
        this.$set(additionalParams, "keyword", this.params.keyword);
      }

      if (this.sortBy) {
        this.$set(additionalParams, "order", this.sortBy);
        this.$set(additionalParams, "order_by", this.sortDesc ? "DESC" : "ASC");
      }

      if (!this.authUser.isSuperAdmin) {
        if (!this.authUser.isAdmin) {
          this.$set(additionalParams, "userProfileId", this.authUser.id);
        } else {
          // if (this.authUser.mHeadquarterId) {
          //   this.$set(params, "mHeadquarterId", this.authUser.mHeadquarterId);
          // }
          // if (this.authUser.mDivisionId) {
          //   this.$set(params, "mDivisionId", this.authUser.mDivisionId);
          // }
          // if (this.authUser.mStationId) {
          //   this.$set(params, "mStationId", this.authUser.mStationId);
          // }
        }
      }

      this.isFetching = true;

      try {
        MRank.api().findAll();
        const promise = await TeachingDare.api().findAll(
          {
            ...additionalParams,
            limit: ctx.perPage,
            offset: (ctx.currentPage - 1) * ctx.perPage,
          },
          {
            save: false,
          }
        );

        let { data: responseData = [], number_of_pages = 1 } =
          promise.response.data;

        data = responseData.map((record) => {
          let user_name = "";

          if (record.user_profile) {
            const userRank = MRank.find(record.user_profile.mRankId);

            user_name = `${record.user_profile.first_name} ${record.user_profile.last_name}`;

            if (userRank) {
              user_name = `${userRank.m_rank_name} ${user_name}`;
            }
          }

          let yearTerm = record.fiscal_year || "";

          if (record.term) {
            yearTerm += `/${record.term}`;
          }

          let classRoom = "";

          if (record.m_class) {
            classRoom += record.m_class.m_class_name;

            const roomNumber = parseInt(record.room);

            if (!isNaN(roomNumber)) {
              classRoom += `/${roomNumber}`;
            }
          }

          const { teaching_plans } = record;

          let totalTimes = 0;
          let hours = 0;
          let mins = 0;

          if (teaching_plans) {
            for (let index = 0; index < teaching_plans.length; index++) {
              const element = teaching_plans[index].totalTime;

              if (element && element.length) {
                const times = element.split(":");

                hours += parseInt(times[0]);
                mins += parseInt(times[1]);
              }
            }
          }

          totalTimes = hours + mins / 60;

          totalTimes = totalTimes ? parseInt(totalTimes) : 0;

          return {
            ...record,
            order: record.transactionId || "",
            plan_student: record.plan_student || 0,
            actual_student: record.actual_student || 0,
            teaching_plan_count: record.teaching_plan_count || 0,
            room: record.room || "-",
            fiscal_year: record.fiscal_year || "-",
            school: record.m_school ? record.m_school.m_school_name : null,
            station: record.m_station ? record.m_station.m_station_name : null,
            amphur: record.m_school.m_amphur
              ? record.m_school.m_amphur.m_amphur_name
              : null,
            province: record.m_school.m_province
              ? record.m_school.m_province.m_province_name
              : null,
            updated_at: record.updatedAt
              ? this.$_formatDateMixin_formatShort(record.updatedAt)
              : "-",
            created_at: record.createdAt
              ? this.$_formatDateMixin_formatShort(record.createdAt)
              : "-",
            yearTerm,
            classRoom,
            user_name,
            time: totalTimes,
            price: 300,
            totalPrice: 300 * totalTimes,
          };
        });

        numberOfPages = number_of_pages;
      } catch (error) {
        console.log(error);
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลแผนการสอนได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetching = false;
      }

      this.numberOfPages = numberOfPages;

      return data;
    },
    refresh() {
      this.$refs.table.refresh();
    },
  },
};
</script>
