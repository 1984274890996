<template>
  <b-modal
    :id="`select-type-create-transaction-modal_${item.id}`"
    centered
    hide-header
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <b-form-group class="d-flex flex-row justify-content-center m-0">
      <b-form-radio v-model="selected" name="some-radios" value="0" class="py-3"
        ><h4 class="font-weight-bold">เบิกกับ บชปส</h4>
        <span class="text-black-50">(ชั่วโมงละ 300 บาท)</span>
      </b-form-radio>
      <b-form-radio v-model="selected" name="some-radios" value="1" class="py-3"
        ><h4 class="font-weight-bold">เบิกกับ อบจ.</h4>
        <span class="text-black-50">(ชั่วโมงละ 600 บาท)</span></b-form-radio
      >
    </b-form-group>

    <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="dark" @click="cancel()"> ยกเลิก </b-button>
      <b-button size="sm" variant="primary" @click="printTransaction(item)">
        <span>ยืนยัน</span>
      </b-button>
    </template>

    <generate-pdf
      :ref="`pdf_${item.id}`"
      :item="item"
      :format="format"
    ></generate-pdf>
  </b-modal>
</template>


<script>
import { generateTemplate } from "../../helpers/template-pdf-transaction";
import GeneratePdf from "../report/GeneratePdf";
import { Auth } from "../../models";

export default {
  components: {
    GeneratePdf,
  },
  props: {
    show: Boolean,
    item: Object,
  },

  data() {
    return {
      isSubmiting: false,
      format: "",
      selected: "",
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  methods: {
    async printTransaction(item) {
      // สั่ง print รายละเอียด
      if (!this.selected)
        return this.$toast.error("กรุณาเลือก 'ตัวเลือกการเบิก'");
      this.format = await generateTemplate(this.authUser, item, this.selected);
      if (this.$refs[`pdf_${item.id}`] && this.format) {
        const { $refs } = this.$refs[`pdf_${item.id}`];
        this.$nextTick(() => {
          $refs.html2Pdf.generatePdf();
        });
      }
    },
    onHidden() {
      this.clearData();
    },

    clearData() {
      this.selected = "";
    },
  },
};
</script>